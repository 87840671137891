
import useStore from "@/hooks/useStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    minButtons: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  methods: {
    isPageActive(page) {
      return this.currentPage === page;
    },
    onClickPreviousPage() {
      if (this.currentPage > 0) this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      if (this.currentPage <= this.totalPages)
        this.$emit("pagechanged", this.currentPage + 1);
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 0) {
        return 0;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.minButtons;
      }

      return this.currentPage - 1;
    },
    pages() {
      const range: any = [];
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.minButtons - 1, this.totalPages - 1);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 0;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages - 1;
    },
  },
});
