
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Pagination from "./Pagination.vue";
import Sidebar from "./Sidebar.vue";
import CardCandidate from "./CardCandidate.vue";
import Analysis from "./Analysis/index.vue";
import useStore from "../../hooks/useStore";
import { computed, defineComponent, reactive } from "vue";
import * as roles from "../../services/mocks/filtersRoles.json";
import * as locales from "../../services/mocks/filtersLocales.json";
import services from "@/services";
import { setCurrentCandidates } from "@/store/candidates";
import { setCurrentLocale } from "@/store/locales";
import { setCurrentRole } from "@/store/roles";
import arrayShuffle from "array-shuffle";
import { cleanFilters } from "@/store/filters";
import { ICandidate, ILocale } from "@/models/candidate.model";
import { cleanCurrentParty } from "@/store/party";

export default defineComponent({
  components: {
    Footer,
    Navbar,
    Pagination,
    Analysis,
    CardCandidate,
    Sidebar,
  },
  props: [],
  data() {
    return {
      pageCount: 1,
      currentPage: 0,
      resultsPerPage: 8,
      noResultsAnalysis: true,
      qtdResultsAnalysis: 0,
      FilterParty: false,
      hasFiltersCandidates: false,
      loading: false,
      textFilter: {
        name: "",
        filter: "",
      },
    };
  },
  setup() {
    const data = useStore();
    const items = reactive({
      roles: roles.data,
      locales: locales.data,
    });

    let currentParty = computed(function () {
      return data.Party.currentParty;
    });

    let currentLocale = computed(function () {
      let dataLocale = {
        preposition: "",
        name: "",
      };
      items.locales.forEach((i: ILocale) => {
        if (i.initials === data.Locale.currentLocale) {
          dataLocale = {
            name: i.name,
            preposition: i.preposition,
          };
        } else if (data.Locale.currentLocale == "br") {
          dataLocale = {
            name: "Brasil",
            preposition: "no",
          };
        }
      });
      return dataLocale;
    });

    let currentRole = computed(function () {
      let dataRole = "";
      items.roles.forEach((i: any) => {
        if (i.id == data.Role.currentRole) dataRole = i.name;
      });

      return dataRole;
    });

    let hasSelectedParty = computed(function () {
      return data.Party.hasSelectedParty;
    });

    let currentCandidates = computed(function () {
      const valuesData: ICandidate[] = [];
      data.Candidates.currentCandidates.objects?.forEach((i) =>
        valuesData.push(i)
      );
      return arrayShuffle(valuesData);
    });

    return {
      items,
      data,
      currentLocale,
      currentRole,
      currentParty,
      currentCandidates,
      hasSelectedParty,
    };
  },
  methods: {
    selectRole(role) {
      if (role == "PRESIDENTE") return "presidente";
      if (role == "DEPUTADO DISTRITAL") return "deputado-distrital";
      if (role == "DEPUTADO FEDERAL") return "deputado-federal";
      if (role == "DEPUTADO ESTADUAL") return "deputado-estadual";
      if (role == "GOVERNADOR") return "governador";
      if (role == "SENADOR") return "senador";
    },
    onPageChange(page) {
      this.currentPage = page;
    },

    paginatedData(candidates) {
      return this.filteredItems(candidates);
    },

    pageQtd(candidates) {
      let candidatesSize = candidates.length,
        viewSize = this.resultsPerPage;
      this.pageCount = Math.ceil(candidatesSize / viewSize);
    },

    filteredItems(candidates) {
      var candidatesResult = candidates;

      if (this.data.Filters.hasSelectedGenderWoman) {
        candidatesResult = candidatesResult.filter((item) =>
          item.gender.includes("FEMININO")
        );
      }

      if (this.data.Filters.hasSelectedGenderMan) {
        candidatesResult = candidatesResult.filter((item) =>
          item.gender.includes("MASCULINO")
        );
      }

      if (this.data.Filters.hasSelectedEthnicityWhite) {
        candidatesResult = candidatesResult.filter((item) =>
          item.ethnicity.includes("BRANCA")
        );
      }

      if (this.data.Filters.hasSelectedEthnicityPPI) {
        candidatesResult = candidatesResult.filter(
          (item) =>
            (item.ethnicity == "PRETA" ||
              item.ethnicity == "PARDA" ||
              item.ethnicity == "INDIGENA") &&
            item
        );
      }

      if (this.data.Filters.hasSelectedElections) {
        candidatesResult = candidatesResult.filter(
          (item) => item.elections != 0 && item.elections_won == 0 && item
        );
      }

      if (this.data.Filters.hasSelectedNElections) {
        candidatesResult = candidatesResult.filter(
          (item) => item.elections == 0 && item
        );
      }

      if (this.data.Filters.hasSelectedElectionsWon) {
        candidatesResult = candidatesResult.filter(
          (item) => item.elections != 0 && item.elections_won != 0 && item
        );
      }

      if (this.data.Party.currentParty.length) {
        candidatesResult = candidatesResult.filter(
          (item) => item.party == this.data.Party.currentParty && item
        );
      }

      if (candidatesResult.length == 0) {
        this.qtdResultsAnalysis = candidatesResult.length;
        this.noResultsAnalysis = true;
        this.hasFiltersCandidates = false;
      } else {
        this.qtdResultsAnalysis = candidatesResult.length;
        this.noResultsAnalysis = false;
        this.hasFiltersCandidates = true;
      }

      this.pageQtd(candidatesResult);
      const start = this.currentPage * this.resultsPerPage,
        end = start + this.resultsPerPage;
      return candidatesResult.slice(start, end);
    },
  },
  async mounted() {
    this.loading = true;
    const roleCandidate = this.$route.params.role.toString();
    const localeCandidate = this.$route.params.locale.toString();
    const yearCandidate = Number(this.$route.params.year);
    try {
      const { data } = await services.dataCandidates.candidatesList(
        yearCandidate,
        localeCandidate,
        roleCandidate
      );
      if(data) this.loading = false;
      setCurrentCandidates(data);
      setCurrentLocale(localeCandidate);
      setCurrentRole(roleCandidate);
      cleanFilters();
      cleanCurrentParty();
    } catch (e) {
      this.loading = false;
      console.log("Erro", e);
    }
  },
});
