
import useStore from "@/hooks/useStore";
import { computed, defineComponent, reactive } from "vue";
import * as roles from "../../services/mocks/filtersRoles.json";
import * as locales from "../../services/mocks/filtersLocales.json";
import { setCurrentParty, cleanCurrentParty } from "@/store/party";
import { setCurrentLocale } from "@/store/locales";
import services from "@/services";
import { setCurrentCandidates } from "@/store/candidates";
import { setCurrentRole } from "@/store/roles";
import { ICandidate, ILocale, IRole } from "@/models/candidate.model";

export default defineComponent({
  data() {
    return {
      isActiveRole: true,
      isOpenSidebar: false,
    };
  },
  setup() {
    const store = useStore();
    const items = reactive({
      roles: roles.data,
      locales: locales.data,
    });

    let currentRole = computed(function () {
      return store.Role.currentRole;
    });

    let currentCandidates = computed(function () {
      return store.Candidates.currentCandidates.objects;
    });

    let currentLocale = computed(function () {
      let dataLocale = {
        initials: "",
        name: "",
      };
      items.locales.forEach((i: ILocale) => {
        if (i.initials === store.Locale.currentLocale) {
          dataLocale = {
            name: i.name,
            initials: i.initials,
          };
        } else if (store.Locale.currentLocale === "br") {
          dataLocale = {
            name: "Brasil",
            initials: "br",
          };
        }
      });
      return dataLocale;
    });

    let hasSelectedParty = computed(function () {
      return store.hasSelectedParty;
    });

    let currentParty = computed(function () {
      return store.Party.currentParty;
    });

    let reduceParty = computed(function () {
      const valuesData: ICandidate[] = [];
      let set = new Set();

      store.Candidates.currentCandidates.objects?.forEach((i) =>
        valuesData.push(i)
      );

      let unionArray = valuesData.filter((item) => {
        if (!set.has(item.party)) {
          set.add(item.party);
          return true;
        }
        return false;
      }, set);

      return unionArray;
    });

    return {
      store,
      items,
      currentRole,
      currentLocale,
      currentParty,
      reduceParty,
      hasSelectedParty,
      currentCandidates,
    };
  },
  methods: {
    selectParty(item: string) {
      setCurrentParty(item);
    },

    clearParty() {
      cleanCurrentParty();
    },

    localeListCandidates(event) {
      if (event.target.value && this.currentRole == "deputado-distrital") {
        setCurrentLocale("df");
        this.handleData(this.currentRole, "df");
      } else if (event.target.value && this.currentRole != "presidente") {
        setCurrentLocale(event.target.value);
        this.handleData(this.currentRole, event.target.value);
      } else {
        setCurrentLocale("br");
        this.handleData(this.currentRole, "br");
      }
    },

    selectRole(item: string) {
      setCurrentRole(item);
      if (
        item === "presidente" ||
        (item === "presidente" && this.currentLocale.initials !== "br")
      ) {
        setCurrentLocale("br");
        this.handleData(item, "br");
      } else if (item === "deputado-distrital") {
        setCurrentLocale("df");
        this.handleData(item, "df");
      } else if (item !== "presidente" && this.currentLocale.initials === "br")
        alert("Selecione um estado!");
      else this.handleData(item, this.currentLocale.initials);
    },

    async handleData(role: string, locale: string) {
      try {
        const { data } = await services.dataCandidates.candidatesList(
          2022,
          locale,
          role
        );
        setCurrentCandidates(data);
        this.$router.replace({
          name: "CandidateList",
          params: {
            year: 2022,
            locale: locale,
            role: role,
          },
        });
      } catch (error) {
        console.log("Erro no carregamento de candidatos", error);
      }
    },
  },
});
