
import { defineComponent } from "vue";
import FiltersAnalysis from "./FiltersAnalysis.vue";

export default defineComponent({
  components: { FiltersAnalysis },
  data() {
    return {
      isOpenAnalisys: true,
    };
  },
});
