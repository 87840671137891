
import IconCandidate from "@/components/IconCandidate.vue";
import {
  cleanInfoCandidateSelected,
  setInfoCandidateSelected,
} from "@/store/candidates";
import services from "@/services";
import { defineComponent } from "vue";

export default defineComponent({
  props: [
    "name",
    "number",
    "party",
    "role",
    "roleID",
    "image",
    "locale",
    "keyCandidate",
    "status",
  ],
  components: { IconCandidate },
  methods: {
    async onClickCandidate(key, locale, role) {
      cleanInfoCandidateSelected();
      try {
        const { dataCandidate } = await services.dataCandidates.candidate(key);
        setInfoCandidateSelected(dataCandidate);

        this.$router.push({
          name: "Candidate",
          params: {
            year: 2022,
            locale: locale,
            role: role,
            keyCandidate: key,
          },
        });
      } catch (error) {
        console.log("Erro no carregamento dos dados de cada candidato", error);
      }
    },
  },
});
