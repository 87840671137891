
import useStore from "@/hooks/useStore";
import services from "@/services";
import {
  setCurrentCandidates,
  setInfoCandidateSelected,
} from "@/store/candidates";
import { computed, defineComponent, reactive } from "vue";
import * as roles from "../services/mocks/filtersRoles.json";
import * as locales from "../services/mocks/filtersLocales.json";
import { setCurrentLocale } from "@/store/locales";
import { setCurrentRole } from "@/store/roles";
export default defineComponent({
  name: "Navbar",
  props: ["candidates"],
  data() {
    return {
      location: false,
      dataInfoCandidates: [],
      dataNameCandidates: [],
      showListCandidates: false,
      searchListCandidates: {
        name: "",
        id: "",
      },
    };
  },
  setup() {
    const store = useStore();
    const listCandidates = [];
    const data = reactive({
      roles: roles.data,
      locales: locales.data,
    });

    let currentRole = computed(function () {
      return store.Role.currentRole;
    });

    let currentCandidates = computed(function () {
      return store.Candidates.currentCandidates.objects;
    });

    let currentLocale = computed(function () {
      return store.Locale.currentLocale;
    });

    return {
      data,
      store,
      listCandidates,
      currentRole,
      currentCandidates,
      currentLocale,
    };
  },
  methods: {
    localeNavbarCandidates(event) {
      if (event.target.value && this.currentRole === "deputado-distrital") {
        setCurrentLocale("df");
        this.handleData(this.currentRole, "df");
      } else if (event.target.value && this.currentRole !== "presidente") {
        setCurrentLocale(event.target.value);
        this.handleData(this.currentRole, event.target.value);
      } else {
        setCurrentLocale("br");
        this.handleData(this.currentRole, "br");
      }
    },

    selectRoleNavbar(event) {
      setCurrentRole(event.target.value);
      if (
        event.target.value === "presidente" ||
        (event.target.value === "presidente" &&
          this.currentLocale.initials !== "br")
      ) {
        setCurrentLocale("br");
        this.handleData(event.target.value, "br");
      } else if (event.target.value === "deputado-distrital") {
        setCurrentLocale("df");
        this.handleData(event.target.value, "df");
      } else if (
        event.target.value !== "presidente" &&
        this.currentLocale.initials === "br"
      )
        alert("Selecione um estado!");
      else this.handleData(event.target.value, this.currentLocale.initials);
    },

    async getCandidates(): Promise<any> {
      try {
        const { data } = await services.dataCandidates.candidatesList(
          2022,
          this.store.Locale.currentLocale,
          this.currentRole
        );
        return data;
      } catch (error) {
        console.log("Erro no carregamento de candidatos", error);
      }
    },

    async handleSearchCandidate(idCandidate) {
      try {
        const { dataCandidate } = await services.dataCandidates.candidate(
          idCandidate
        );
        setInfoCandidateSelected(dataCandidate);

        this.$router.push({
          name: "Candidate",
          params: {
            year: 2022,
            locale: this.currentLocale,
            role: this.currentRole,
            keyCandidate: idCandidate,
          },
        });
      } catch (error) {
        console.log("Erro no carregamento da pessoa candidata", error);
      }
    },

    async handleData(role: any, locale: any) {
      try {
        const { data } = await services.dataCandidates.candidatesList(
          2022,
          this.currentLocale,
          this.currentRole
        );
        setCurrentCandidates(data);
        if (this.$router.currentRoute.value.name === "CandidateList") {
          this.$router.replace({
            name: "CandidateList",
            params: {
              year: 2022,
              locale: locale,
              role: role,
            },
          });
        }
      } catch (error) {
        console.log("Erro no carregamento de candidatos", error);
      }
    },
  },
  computed: {
    filteredList() {
      this.getCandidates();
      return this.store.Candidates.currentCandidates.objects?.filter(
        (candidates) => {
          return candidates.name
            .toLowerCase()
            .includes(this.searchListCandidates.name.toLowerCase());
        }
      );
    },
  },
});
