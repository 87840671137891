
import useStore from "@/hooks/useStore";
import {
  cleanFilters,
  setElections,
  setElectionsWon,
  setEthnicityPPI,
  setEthnicityWhite,
  setGenderMan,
  setGenderWoman,
  setNElections,
} from "@/store/filters";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: [],
  data() {
    return {
      checkedElections: false,
      checkedElectionsWon: false,
      checkedNElections: false,
      checkedWoman: false,
      checkedMan: false,
      checkedPPI: false,
      checkedWhite: false,
    };
  },
  setup() {
    const store = useStore();
    let hasSelectedElectionsWon = computed(function () {
      return store.Filters.hasSelectedElectionsWon;
    });

    let hasSelectedElections = computed(function () {
      return store.Filters.hasSelectedElections;
    });

    return {
      store,
      hasSelectedElectionsWon,
      hasSelectedElections,
    };
  },
  watch: {
    checkedElections(value) {
      setElections(value);
      if (value == true) {
        this.checkedElectionsWon = false;
        this.checkedNElections = false;
      }
    },
    checkedElectionsWon(value) {
      setElectionsWon(value);
      if (value == true) {
        this.checkedElections = false;
        this.checkedNElections = false;
      }
    },
    checkedNElections(value) {
      setNElections(value);
      if (value == true) {
        this.checkedElectionsWon = false;
        this.checkedElections = false;
      }
    },
    checkedWoman(value) {
      setGenderWoman(value);
      if (value == true) {
        this.checkedMan = false;
      }
    },
    checkedMan(value) {
      setGenderMan(value);
      if (value == true) {
        this.checkedWoman = false;
      }
    },
    checkedPPI(value) {
      setEthnicityPPI(value);
      if (value == true) {
        this.checkedWhite = false;
      }
    },
    checkedWhite(value) {
      setEthnicityWhite(value);
      if (value == true) {
        this.checkedPPI = false;
      }
    },
  },
  methods: {
    clearAnalysis() {
      cleanFilters();
      this.checkedElections = false;
      this.checkedElectionsWon = false;
      this.checkedNElections = false;
      this.checkedWoman = false;
      this.checkedMan = false;
      this.checkedPPI = false;
      this.checkedWhite = false;
    },
  },
});
